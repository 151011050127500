const skills = [
    {
        id: '0',
        name: 'React',
    },
    {
        id: '1',
        name: 'Redux',
    },
    {
        id: '2',
        name: 'JavaScript',
    },
    {
        id: '3',
        name: 'CSS',
    },
    {
        id: '4',
        name: 'HTML',
    },
    {
        id: '5',
        name: 'Tailwind CSS',
    },
    {
        id: '6',
        name: 'Bootstrap',
    },
    {
        id: '7',
        name: 'WordPress',
    },
    {
        id: '8',
        name: 'PHP',
    },
    {
        id: '9',
        name: 'Figma',
    },
    {
        id: '10',
        name: 'Adobe XD',
    },
]

export default skills;
